import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component';

import { LATEST_RATE_SHEET } from '../../../data/latest-rate-sheet.data';

const Article = () => (
  <NewsArticle>
    <h1 className='page-title'>Changes to our mortgage products</h1>
    <p>With effect from Wednesday 6th October 2021 we will be making the following changes to our mortgage product range:</p>
    <p><strong>Summary of changes</strong></p>

    <p>All of our fixed rate end dates will be extended as below:</p>
    <ul>
      <li>2 year fixed rates – 31st January 2024</li>
      <li>3 year fixed rates – 31st January 2025</li>
      <li>5 year fixed rates – 31st January 2027</li>
    </ul>
    <p>We have extended our Buy to Let product range to all of our Intermediary partners. </p>
    <p>We have also reduced some of the Buy to Let interest rates including:</p>

    <p><strong>Buy to Let Existing Customer Switching Range:</strong></p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
      <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have reduced </li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have reduced</li>
      <li>2 Year Tracker Standard at 65% LTV has reduced</li>
      <li>2 Year Tracker Fee Saver at 60% and 65% LTV have reduced</li>
    </ul>

    <p><strong>Buy to Let Existing Customer Borrowing More Range:</strong></p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
      <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have reduced </li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have reduced</li>
      <li>2 Year Tracker Standard at 65% LTV has reduced</li>
      <li>2 Year Tracker Fee Saver at 60% and 65% LTV have reduced</li>
    </ul>
    
    <p><strong>Buy to Let Remortgage Range:</strong></p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
      <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have reduced </li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have reduced</li>
      <li>2 Year Tracker Standard at 65% LTV has reduced</li>
      <li>2 Year Tracker Fee Saver at 60% and 65% LTV have reduced</li>
    </ul>

    <p><strong>Buy to Let Purchase Range:</strong></p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
      <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have reduced </li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have reduced</li>
      <li>2 Year Tracker Standard at 65% LTV has reduced</li>
      <li>2 Year Tracker Fee Saver at 65% LTV has reduced</li>
    </ul>

    <p>There are no pricing changes to any of our residential interest rates.</p>
    <p>An updated mortgage rate sheet will be published on Wednesday 6th October 2021 to reflect these
      changes.</p>

    <p><a title="This PDF opens in a new window" target="_blank" href={LATEST_RATE_SHEET.href}>Link to our latest rate
      sheet</a></p>

  </NewsArticle>
);

export default Article;
